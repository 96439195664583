import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { ToastContainer, toast } from "react-toastify";
import {
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from "@mui/material";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";

function AdminActions() {
  const [loading, setLoading] = useState(false); // State for loading
  const [splitHistory, setSplitHistory] = useState([]);
  const [dueSplits, setDueSplits] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [splitData, setSplitData] = useState({});
  const [AnchorEl, setAnchorEl] = useState(null);
  const [newAnchorEl, setNewAnchorEl] = useState(null);

  useEffect(() => {
    fetchDueSplits();
    fetchSplitHistory(1);
  }, []);

  const fetchDueSplits = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/action/due-stock-split/");
      setDueSplits(response.data.data);
    } catch (error) {
      console.error("Error fetching due splits:", error);
      toast.error(error?.response?.data?.message || "Error fetching blogs");
    } finally {
      setLoading(false);
    }
  };

  const fetchSplitHistory = async (value) => {
    setLoading(true);
    try {
      const response = await axios.post("/action/stock-split-history/", {
        page: value,
        limit: 10,
      });
      setSplitHistory(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching split history:", error);
      toast.error(error?.response?.data?.message || "Error fetching blogs");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchSplitHistory(value);
  };

  const handleOptionsClick = (event, split) => {
    setAnchorEl(event.currentTarget);
    setSplitData(split);
    console.log(splitData);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSplitData({});
  };

  const handleNewOptionsClick = (event, split) => {
    setNewAnchorEl(event.currentTarget);
    setSplitData(split);
  };

  const handleNewMenuClose = () => {
    setNewAnchorEl(null);
    setSplitData({});
  };

  const actionSplit = async (id) => {
    setLoading(true);
    try {
      const response = await axios.post("/action/stock-split-action/", {
        stock_split_id: id,
      });
      if (response.status === 200) {
        toast.success("Split Action Successfully Done");
      }
    } catch (error) {
      console.error("Error taking split action:", error);
      toast.error(error?.response?.data?.message || "Error fetching blogs");
    } finally {
      setLoading(false);
      await fetchDueSplits();
      setAnchorEl(null);
    }
  };

  const deleteSplit = async (id) => {
    setLoading(true);
    try {
      return toast.warn("Not firmed up yet");
    } catch (error) {
      console.error("Error deleting split:", error);
      toast.error(error?.response?.data?.message || "Error fetching blogs");
    } finally {
      setLoading(false);
      setAnchorEl(null);
      setNewAnchorEl(null);
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {/* Due Splits */}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Due Splits</h4>
          </div>
          <div className="card-body">
            {dueSplits.length > 0 && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Country</TableCell>
                      <TableCell>Symbol</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Split From</TableCell>
                      <TableCell>Split To</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dueSplits.map((split) => (
                      <TableRow key={split.id}>
                        <TableCell>
                          <Typography variant="body1">
                            {split.symbol_symbol.country_country.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {split.symbol_symbol.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">{split.date}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {parseFloat(split.split_from)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {parseFloat(split.split_to)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              handleOptionsClick(e, split);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={AnchorEl}
                            open={Boolean(AnchorEl)}
                            onClose={() => {
                              handleMenuClose();
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                // Add your Edit function here
                                actionSplit(split.id);
                              }}
                            >
                              Action
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                // Add your Delete function here
                                deleteSplit(split.id);
                              }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
            {dueSplits.length === 0 && (
              <>
                <div>
                  <p>You have no due splits</p>
                </div>
              </>
            )}
          </div>
        </div>
      </Box>

      {/* Blog Cards Box */}
      <div className="card h-100">
        <div className="card-header">
          <h4 className="mb-0">Split History</h4>
        </div>
        <div className="card-body">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Country</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Split From</TableCell>
                <TableCell>Split To</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {splitHistory.map((split) => (
                <TableRow key={split.id}>
                  <TableCell>
                    <Typography variant="body1">
                      {split.symbol_symbol.country_country.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {split.symbol_symbol.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{split.date}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {parseFloat(split.split_from)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {parseFloat(split.split_to)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {split.status ? "Closed" : "Pending"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(e) => {
                        handleNewOptionsClick(e, split);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={newAnchorEl}
                      open={Boolean(newAnchorEl)}
                      onClose={() => {
                        handleNewMenuClose();
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          // Add your Delete function here
                          deleteSplit(split.id);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AdminActions;
